import { inject as i } from "vue";
const t = Symbol(), e = {
  install(n, { container: o }) {
    n.provide(t, o);
  }
};
function c(n) {
  const o = i(t);
  if (!o)
    throw new Error("Container not found, did you forget to install the dioc plugin?");
  return o.bind(n);
}
export {
  e as diocPlugin,
  c as useService
};
