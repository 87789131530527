var v = Object.defineProperty;
var w = (r, t, e) => t in r ? v(r, t, { enumerable: !0, configurable: !0, writable: !0, value: e }) : r[t] = e;
var s = (r, t, e) => (w(r, typeof t != "symbol" ? t + "" : t, e), e), c = (r, t, e) => {
  if (!t.has(r))
    throw TypeError("Cannot " + e);
};
var a = (r, t, e) => (c(r, t, "read from private field"), e ? e.call(r) : t.get(r)), h = (r, t, e) => {
  if (t.has(r))
    throw TypeError("Cannot add the same private member more than once");
  t instanceof WeakSet ? t.add(r) : t.set(r, e);
}, u = (r, t, e, o) => (c(r, t, "write to private field"), o ? o.call(r, e) : t.set(r, e), e);
import { S as b, c as i } from "./container-9dabdb69.js";
import { C as $ } from "./container-9dabdb69.js";
var n;
class m {
  constructor() {
    /**
     * The internal event stream of the service
     */
    s(this, "event$", new b());
    /** The container the service is bound to */
    h(this, n, void 0);
    if (!i)
      throw new Error(
        `Tried to initialize service with no container (ID: ${this.constructor.ID})`
      );
    u(this, n, i);
  }
  /**
   * Binds a dependency service into this service.
   * @param service The class reference of the service to bind
   */
  bind(t) {
    if (!i)
      throw new Error("No currentContainer defined.");
    return i.bind(t, this.constructor);
  }
  /**
   * Returns the container the service is bound to
   */
  getContainer() {
    return a(this, n);
  }
  /**
   * Emits an event on the service's event stream
   * @param event The event to emit
   */
  emit(t) {
    this.event$.next(t);
  }
  /**
   * Returns the event stream of the service
   */
  getEventStream() {
    return this.event$.asObservable();
  }
}
n = new WeakMap();
export {
  $ as Container,
  m as Service,
  i as currentContainer
};
